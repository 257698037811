
import { defineComponent, computed, onMounted, onUpdated } from 'vue';
import SectionHeader from '@/components/global/SectionHeader.vue';
import Popper from '@/components/commentary/Popper.vue';
import PrimaryButton from '@/components/global/PrimaryButton.vue';
import { getIntro, intro } from '@/api/commentary';
import { isLoaded } from '@/interfaces/RemoteData';
import ArticleContainer from '@/components/global/ArticleContainer.vue';
import TableOfContents from '@/components/global/TableOfContents.vue';
import { connectQuranRefsToPopper, initializePopper } from '@/components/commentary/popper';
import { useRoute, useRouter } from 'vue-router';
import { createLinks } from '@/components/commentary/linker';
import routeParamToString from '@/router/param_as_string';
import scrollToHash from '@/components/global/scrollToHash';
import HowToQuote from '@/components/global/HowToQuote.vue';
import { useMeta } from 'vue-meta';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  components: {
    SectionHeader,
    PrimaryButton,
    Popper,
    ArticleContainer,
    TableOfContents,
    HowToQuote,
  },
  setup() {
    const router = useRouter();
    const { t } = useI18n({
      useScope: 'global',
      inheritLocale: true,
    });
    const route = useRoute();
    // const title = computed(
    //   () => `${t('navigation.intertexts')} ${t('global.overview')}`,
    // );
    // const description = computed(
    //   () => `ssss${t('navigation.intertexts')} ${t('global.overview')}`,
    // );
    // const canonical = computed(
    //   () => `https://corpuscoranicum.org/${route.params.lang}/commentary`,
    // );
    // const alternate_de = computed(
    //   () => 'https://corpuscoranicum.org/de/commentaryddd',
    // );
    // const alternate_en = computed(
    //   () => 'https://corpuscoranicum.org/en/commentary',
    // );
    // const alternate_fr = computed(
    //   () => 'https://corpuscoranicum.org/fr/commentary',
    // );
    // const meta = computed(() => ({
    //   title: title.value,
    //   description: description.value,
    //   link: [
    //     { rel: 'canonical', href: canonical.value },
    //     { rel: 'alternate', hreflang: 'de', href: alternate_de.value },
    //     { rel: 'alternate', hreflang: 'en', href: alternate_en.value },
    //     { rel: 'alternate', hreflang: 'fr', href: alternate_fr.value },
    //     { rel: 'alternate', hreflang: 'x-default', href: alternate_de.value },
    //   ],
    //   htmlAttrs: {
    //     lang: route.params.lang,
    //   },
    // }));
    // useMeta(meta);
    getIntro(routeParamToString(route.params.intro));

    function makeLinksAndPoppers() {
      createLinks(router, route);

      // Hook up Quran references to popper.
      connectQuranRefsToPopper(routeParamToString(route.params.lang));
    }

    onMounted(() => {
      initializePopper();
      scrollToHash(route);
      makeLinksAndPoppers();
    });

    onUpdated(() => {
      makeLinksAndPoppers();
    });

    return {
      intro,
      isLoaded,
    };
  },
});
